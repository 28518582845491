import React from 'react'
import useResponsiveSelector from 'hooks/useResponsiveSelector'
import LeBusinessTravellerLogo from './LeBusinessTravellerLogo'
import LuxuryEscapesSmallLogo from './LuxuryEscapesSmallLogo'

export interface ResponsiveLogoProps {
  /** @default icon+text */
  mobileVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  tabletVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  desktopVariant?: 'icon' | 'icon+text'
}

type Props = React.SVGAttributes<SVGSVGElement> & ResponsiveLogoProps

function ResponsiveLeBusinessTravellerLogo(props: Props) {
  const {
    mobileVariant = 'icon',
    tabletVariant = 'icon+text',
    desktopVariant = 'icon+text',
    ...rest
  } = props

  const showSmall = useResponsiveSelector({
    mobile: mobileVariant === 'icon',
    tablet: tabletVariant === 'icon+text',
    // if this is set to 'icon+text', as would be intuitive,
    // at widths 990px-1200px, it becomes an icon for some reason
    desktop: desktopVariant === 'icon',
  })

  return <>
    {showSmall && <LuxuryEscapesSmallLogo {...rest} width={35} />}
    {!showSmall && <LeBusinessTravellerLogo {...rest} />}
  </>
}

export default ResponsiveLeBusinessTravellerLogo
